import axios from 'axios'
import { createStore, createEffect } from 'effector'
import landingConfig from 'src/configs/landing'

const getTableForLanding = createEffect(async ({ tableName, key }: { tableName: string; key: string }) => {
  const { data } = await axios.post(landingConfig.getTable(tableName), {
    page: 0,
    limit: 100,
    query: {}
  })

  return { data, key }
})

const getLandingDetail = createEffect(async () => {
  const res = await axios.post(landingConfig.getTable('keyValues'), {
    query: {
      $or: [{ 'data.groupName': 'landing' }, { 'data.groupName': 'shared' }]
    }
  })

  const data: Record<string, any> = {}

  res.data?.data.forEach((item: {
    data: {
      groupName: string
      key: string
      value?: string
    }
  }) => {
    const { key, value } = item.data
    data[key] = value
  })

  return data
})

const landing = createStore<any>({
  name: 'string',
  artistsItems: {
    data: [],
    loading: true
  },
  workItItems: {
    data: [],
    loading: true
  },
  platforms: {
    loading: true,
    data: []
  },
  platformSlides: {
    loading: true,
    data: []
  },
  faqItems: {
    loading: true,
    data: []
  },
  controlsSlides: {
    loading: true,
    data: []
  },
  landingDetails: {
    loading: true,
    data: {}
  }
})
  .on(getTableForLanding, (state: any) => {
    return {
      ...state,
      artists: {
        ...state.artists,
        loading: true
      }
    }
  })
  .on(getTableForLanding.doneData, (state: any, { key, data: payload }) => {
    return {
      ...state,
      [key]: {
        data: payload.data,
        loading: false
      }
    }
  })
  .on(getLandingDetail, (state: any) => {
    return {
      ...state,
      landingDetails: {
        ...state.landingDetails,
        loading: true
      }
    }
  })
  .on(getLandingDetail.doneData, (state: any, payload) => {
    return {
      ...state,
      landingDetails: {
        loading: false,
        data: payload
      }
    }
  })

export { getTableForLanding, getLandingDetail }

export default { landing }
