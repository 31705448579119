// ** React Imports
import React from 'react'

// ** MUI Imports
import { SvgIcon, SxProps, Theme } from '@mui/material'

// ** Source code Imports
import { Mode } from 'src/@core/layouts/types'

interface Props {
  mode: Mode
  width?: string | number
  height?: string | number
  sx?: SxProps<Theme> | undefined
}

const Logotype = ({ mode = 'dark', width = '100%', height = '100%', sx }: Props) => {
  const fill = {
    light: '#141414',
    dark: '#FFFFFF',
    'semi-dark': '#FFFFFF'
  }[mode]

  return (
    <SvgIcon
      sx={{ transition: '200ms', width, height, ...sx }}
      width={width}
      height={height}
      style={{ transition: '200ms' }}
      viewBox='0 0 43 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.1417 0.664994C13.2848 1.12009 14.3264 1.81063 15.2031 2.69459L12.9799 5.38571C11.7427 4.27374 10.1635 3.65147 8.52363 3.62973C7.8232 3.59029 7.1279 3.77406 6.53188 4.15618C6.29405 4.32607 6.1021 4.55582 5.97419 4.82369C5.84628 5.09156 5.78664 5.38871 5.8009 5.68703C5.78717 6.09262 5.90521 6.4913 6.13621 6.81959C6.44789 7.18668 6.83989 7.47166 7.27962 7.65081C8.07615 8.0133 8.89233 8.32782 9.72405 8.59292C11.7918 9.28556 13.3152 10.1284 14.2944 11.1212C15.2735 12.1141 15.7619 13.5307 15.7597 15.3709C15.7821 16.5994 15.4455 17.8064 14.7939 18.8344C14.1031 19.8714 13.1366 20.6797 12.0109 21.1618C10.6508 21.7497 9.18617 22.0353 7.71221 22C6.2025 22.0294 4.70197 21.7517 3.29612 21.1826C2.07461 20.6769 0.95637 19.9378 0 19.0041L2.43101 16.2506C3.1503 16.8969 3.96691 17.4175 4.84861 17.7919C5.71141 18.1414 6.63117 18.3166 7.55791 18.308C8.39271 18.3493 9.2175 18.1059 9.90515 17.6153C10.1863 17.3957 10.4116 17.1089 10.5617 16.7795C10.7118 16.4501 10.7822 16.0879 10.7669 15.7242C10.7791 15.2614 10.6627 14.8047 10.4316 14.408C10.1476 13.9954 9.7637 13.667 9.31834 13.4556C8.58308 13.0877 7.81919 12.7842 7.03485 12.5482C4.80391 11.8486 3.21564 10.9838 2.27006 9.95406C1.79622 9.43193 1.42674 8.81842 1.18278 8.14867C0.938815 7.47884 0.825163 6.76593 0.84834 6.0507C0.827236 4.91167 1.1559 3.79499 1.78721 2.86084C2.44634 1.91479 3.35058 1.18008 4.39595 0.741189C5.59316 0.229401 6.8794 -0.0228753 8.17488 6.6165e-06C9.52536 -0.0302739 10.8698 0.195105 12.1417 0.664994Z'
        fill={fill}
      />
      <path d='M21.6781 21.4735H16.873V11H21.6781V21.4735Z' fill={fill} />
      <path d='M28.0423 21.4736H23.2373V6.07495H28.0423V21.4736Z' fill={fill} />
      <path d='M34.2523 21.4736H29.4473V0.516113H34.2523V21.4736Z' fill={fill} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.5953 0.16626L29.4473 10.6502L34.2489 10.6536L42.4004 0.169723L37.5953 0.16626Z'
        fill='#9000FF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.317 9.45535L21.6709 8.29852L16.876 5.94336V10.6537L19.317 9.45535Z'
        fill={fill}
      />
    </SvgIcon>
  )
}

export default Logotype
