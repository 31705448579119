import Flag from 'react-world-flags'

import ISO3166 from 'src/configs/ISO3166-1.alpha2'

interface IProps {
  name: any
  width?: string | number
  height?: string | number
}

const CountrieFlag = ({ name, width, height, ...props }: IProps) => {
  if (!ISO3166[name]) return null

  return <Flag width={width} height={height} {...props} code={ISO3166[name]} />
}

export default CountrieFlag
